import { autoinject } from 'aurelia-framework';
import { NetlogHttpClient } from 'lib/http';
import { Models } from 'models/core';
import { AuthHttpClient } from 'services/auth-http-client';
import { BaseApiService } from 'services/base-api-service';

@autoinject
export class NetStorageService extends BaseApiService<Models.NetType> {
  constructor(
    httpClient: AuthHttpClient,
    private client: NetlogHttpClient
  ) {
    super(httpClient, Models.NetType);
  }

  public getCurrentStorage(netId: number) {
    return this.client.get(
      {
        url: `api/nets/${netId}/storages`,
        model: NetStorageLocationDto,
      },
      { cache: false }
    );
  }

  public removeFromStorage(netId: number) {
    return this.client.delete({
      url: `api/nets/${netId}/storages`,
    });
  }

  public updateStorage(dto: UpdateNetStorageLocationDto) {
    return this.client.post({
      url: `api/nets/${dto.NetId}/storages`,
      data: dto,
    });
  }
}

export class NetStorageLocationDto {
  NetId: number;

  StorageName: string;
  StorageHistoryId: number;
  ServiceStationStorageId: number;

  ServiceStationId: number;
  ServiceStationName: string;

  In: Date;
  Out?: Date;
}

export class UpdateNetStorageLocationDto {
  NetId: number;

  ServiceStationStorageId: number;
  SortIndex: number;
}
